<template>
<div class="toggle">
  <label class="switch">
    <input ref="checkbox" type="checkbox" @click="toggleCheckbox">
    <div class="slider round"></div>
  </label>
  <span class="checkbox-label">{{ label }}</span>
</div>
</template>

<script>
  export default {
    name: "Toggle",
    props: {
      value: {
        type: Boolean,
        default: false,
      },
      label: {
        type: String,
        default: ''
      },
    },
    data () {
      return {
        checkbox: this.value
      }
    },
    mounted() {
      this.$refs.checkbox.checked = this.checkbox
    },
    methods: {
      toggleCheckbox() {
        this.checkbox = !this.checkbox
        this.$emit('change', this.checkbox)
        this.$emit('input', this.checkbox)
      }
    }
  }
</script>

<style lang="scss" scoped>
.toggle {
  display: flex;
  align-items: center;
  gap: 5px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 21px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--new-front-input-border);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 19px;
  width: 19px;
  left: 1px;
  bottom: 1px;
  background-color: #fff;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #6750A4;
}

input:focus + .slider {
  box-shadow: 0 0 1px #6750A4;
}

input:checked + .slider:before {
  transform: translateX(13px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.checkbox-label {
  font-family: $Gilroy;
  font-size: $h4;
  color: var(--new-front-primary-font-color)
}
</style>
