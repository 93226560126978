export const TRANSACTION_HISTORY_TYPE_ENUM = {
    COMMISSION: 'commission',
    COMMISSION_CREATE_ORDER: 'commission_create_order',
    COMMISSION_EXECUTE_ORDER: 'commission_execute_order',
    COMMISSION_WALLET_DEPOSIT: 'commission_wallet_deposit',
    COMMISSION_PAYOUT_DEPOSIT: 'commission_payout_deposit',
    COMMISSION_RECURRENT_DEPOSIT: 'commission_recurrent_deposit',
    COMMISSION_PERSONAL_DEPOSIT: 'commission_personal_deposit',
    COMMISSION_TRANSFER: 'commission_transfer',
    COMMISSION_WALLET_WITHDRAWAL: 'commission_wallet_withdrawal',
    COMMISSION_PAYOUT_WITHDRAWAL: 'commission_payout_withdrawal',
    DEPOSIT_COLLECT: 'deposit_collect',
    COMMISSION_RECURRENT_WITHDRAWAL: 'commission_recurrent_withdrawal',
    COMMISSION_PERSONAL_WITHDRAWAL: 'commission_personal_withdrawal',
    // Старая комиссия
    COMMISSION_WITHDRAWAL: 'commission_withdrawal',

    DEPOSIT: 'deposit',
    WITHDRAWAL: 'withdrawal',
    NETWORK_FEE: 'network_fee',
    TRANSFER: 'transfer',

    BRIDGE_INTERNAL: 'bridge_internal',
    BRIDGE_EXTERNAL: 'bridge_external',

    BRIDGE_INTERNAL_FEE: 'bridge_internal_fee',
    BRIDGE_EXTERNAL_FEE: 'bridge_external_fee',

    EXCHANGE_INTERNAL: 'exchange_internal',
    EXCHANGE_AUTO: 'exchange_auto',

    EXCHANGE_INTERNAL_FEE: 'exchange_internal_fee',
    EXCHANGE_AUTO_FEE: 'exchange_auto_fee',
    KYT_ADDRESS_FEE: 'kyt_address_fee',
    // EXCHANGE: 'exchange',
    // PARTNERS: 'partners',
    // REFERRALS: 'referrals',
    // ORFAN_DEPOSIT: 'orfan_deposit',
    // ORFAN_WITHDRAWAL: 'orfan_withdrawal',
    // ROLLING_RESERVE: 'rolling_reserve',
    ORDER_TRANSACTION: 'order_transaction',
    DEPOSIT_PAYOUT_BALANCE: 'deposit_payout_balance',
    DEPOSIT_RECURRENT: 'deposit_recurrent',
    DEPOSIT_PERSONAL: 'deposit_personal',
    DEPOSIT_WALLET: 'deposit_wallet',
    KYT_TRANSACTION_FEE: 'kyt_transaction_fee',
    KYT_WITHDRAWAL_ADDRESS_FEE: 'kyt_withdrawal_address_fee',
    ORPHAN_DEPOSIT_WITHDRAWAL_FEE: 'orphan_withdrawal_fee',
    ORPHAN_DEPOSIT_WITHDRAWAL: 'orphan_withdrawal',
    GAS_DEPOSIT: 'gas_deposit',
    ORPHAN_DEPOSIT: 'orphan_deposit',
    PAYOUT_AUTO_SWAP: 'payout_auto_swap',
    PAYOUT_AUTO_SWAP_FEE: 'payout_auto_swap_fee',
    // COMMISSION_WITHDRAWAL: 'commission_withdrawal'
};
