<template>
  <div
    v-if="showSummary"
    class="summary-block"
  >
    <span class="amount">{{ amount }}</span>
    <span class="currency">USD</span>
    <base-button class="desktop-analytics-btn" type="primary" @click="goToAnalytics" :label="$t('organization.analytics')">
      <template #prepend>
        <svg
            class="icon"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
        >
          <path d="M4.5 15V10.5" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M13.5 15V7.5" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M9 15V3" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </template>
    </base-button>
    <svg
        @click="goToAnalytics"
        class="icon mobile-analytics-btn"
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
    >
      <path d="M4.5 15V10.5" stroke="#744DB2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13.5 15V7.5" stroke="#744DB2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M9 15V3" stroke="#744DB2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>
</template>

<script>
import { mapState } from "vuex";
import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";

export default {
  name: "SummaryHeader",
  components: {BaseButton},
  computed: {
    ...mapState('summary',
        ['summary'],
    ),
    sectionParser({ $route }) {
      const { name } = $route

      let parseName = ''

      switch (name) {
        case "dashboard":
          parseName = 'TOTAL'
          break;
        case "wallets":
          parseName = 'PAY_IN'
          break;
        case "businessWallet":
          parseName = 'BUSINESS'
          break;
        case "payout-wallet-balance":
          parseName = 'PAY_OUT'
          break;
        case "recurrentsAddress":
          parseName = 'RECURRENT'
          break;
        case "personal-addresses":
          parseName = 'PERSONAL'
          break;
          // case "advanced-balances":
          //   parseName = 'ADVANCED'
          // break;
        default:
          parseName = ''
      }

      return parseName
    },
    amount({ sectionParser, summary }) {
      if (!!sectionParser) {
        if (!!summary[sectionParser]) {
          const amount = Number(summary[sectionParser]).toLocaleString('ru-RU')
          return amount.toString().replace(/,/g, '.')
        }
        else {
          return '0'
        }
      } else {
        return '0'
      }
    },
    showSummary({ sectionParser }) {
      return !!sectionParser
    }
  },
  methods: {
    goToAnalytics() {
      const params = {}

      if (this.sectionParser !== 'TOTAL') {
        params.wallet = this.sectionParser
      }

      this.$router.push({
        name: 'analytics',
        params
      })
    }
  },
}
</script>

<style
  lang="scss"
  scoped
>
.desktop-analytics-btn {
  margin-left: 20px;
  @media screen and (max-width: 576px){
    display: none;
  }
}
.mobile-analytics-btn {
  @media screen and (min-width: 576px){
    display: none;
  }
}
  .summary-block {
    display: flex;
    align-items: center;
    justify-content: right;

    font-family: var(--new-front-font-family);
    font-weight: 500;
    font-size: $h4;

    .amount {
      color: var(--new-front-text-success-bg-primary);
    }
    .currency {
      color: var(--new-front-text-primary-bg-primary);
      margin-left: 5px;
    }
    .icon {
      margin-left: 5px;
      cursor: pointer;
    }
  }
</style>
